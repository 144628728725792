<template>
  <action-dispatcher
    action="professional/search"
    :parameters="$route.query"
    @finally="onFinally"
  >
    <div class="views-professionals">
      <div class="container">
        <div class="d-flex align-items-start">
          <div class="flex-fill">
            <h2 class="page-title text-center text-lg-left text-uppercase">
              Profissionais
            </h2>
            <p
              class="page-description text-center text-lg-left text-light mb-5"
            >
              Conheça milhares de especialistas em diversas áreas.
            </p>
          </div>
          <div class="d-none d-lg-block">
            <router-link
              v-if="
                !haveProfile &&
                  $user.profile &&
                  $user.profile.emblem &&
                  $user.profile.emblem.is_active
              "
              :to="{ name: 'my-professional-profile' }"
              class="btn btn-primary"
            >
              Criar perfil profissional
            </router-link>
          </div>
        </div>
        <div class="d-flex d-lg-none justify-content-center mb-4">
          <router-link
            v-if="
              !haveProfile &&
                $user.profile &&
                $user.profile.emblem &&
                $user.profile.emblem.is_active
            "
            :to="{ name: 'my-professional-profile' }"
            class="btn btn-primary"
          >
            Criar perfil profissional
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-5 mb-3 pt-lg-4">
            <search-input
              v-model="paramsFilter.q"
              placeholder="Pesquisar"
              class="mr-2"
              label=""
            />
          </div>
          <div class="col-lg-3 pr-lg-0">
            <div class="pr-2">
              <select-dropdown
                full-object
                veryShortEllipsis
                label="Categorias"
                prop-name="name"
                labelColor="light"
                :options="categories"
                v-model="selected_categories"
                @submit="submitCategoriesFilter"
              />
            </div>
          </div>
          <div class="col-lg-2 pr-3 pr-lg-1 my-lg-0 my-3">
            <select-dropdown
              full-object
              veryShortEllipsis
              :selectOne="true"
              label="Estado"
              labelColor="light"
              :options="districts"
              v-model="selected_state"
              @submit="submitStates"
            />
          </div>
          <div class="col-lg-2 pr-3 mb-3 mb-lg-0">
            <select-dropdown
              full-object
              :veryShortEllipsis="true"
              prop-name="name"
              label="Cidade"
              labelColor="light"
              :options="citiess"
              v-model="selected_cities"
              @submit="submitCities"
            />
          </div>
        </div>
        <div class="row justify-content-end mb-4">
          <div class="col-lg-2">
            <div
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-lg-end
              "
            >
              <order-by v-model="paramsFilter.order" />
              <button class="btn btn-sm text-primary" @click="clearFilters">
                <small>Limpar</small>
              </button>
            </div>
          </div>
        </div>
        <div v-if="selected_categories.length" class="mb-4">
          <h3 class="text-uppercase mb-3">
            Resultados para "{{
              selected_categories.map(id => id_name_map[id]).join(", ")
            }}"
          </h3>
          <div v-for="(cat, i) in selected_categories" :key="`cat-${i}`">
            <b-form-group
              v-model="selected_tags[i]"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-checkbox-group
                :id="`checkbox-group-${i}`"
                class="font-weight-bold"
                :aria-describedby="ariaDescribedby"
                :name="`flavour-${i}`"
              ></b-form-checkbox-group>
              <!-- normalizedTags[normalizeCat(cat)].map(text => ({ text })) -->
            </b-form-group>
          </div>
          <hr />
        </div>

        <div v-if="professionals.length" class="row">
          <div
            v-for="(professional, index) in professionals"
            :key="`professional-${index}`"
            class="col-md-6 col-lg-3 mb-4 d-flex"
          >
            <professional-card :professional="professional" />
          </div>

          <div class="d-flex w-100 justify-content-center">
            <paginator
              v-model="paramsFilter.page"
              :pagination="pagination"
              class="mb-5"
            />
          </div>
        </div>
        <div v-else class="d-flex justify-content-center pt-4">
          <div v-if="showLoading" class="d-flex justify-content-center p-5">
            <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
          </div>
          <h4 v-else class="text-secondary">
            Sem profissionais disponíveis no momento
          </h4>
        </div>

        <div class="d-lg-none py-5"></div>
      </div>
    </div>
  </action-dispatcher>
</template>

<script>
import OrderBy from "@/components/filters/OrderBy.vue";
import { normalizeText, clearObject } from "@/functions/helpers";
import { paramsMixin } from "@/mixins";
import { mapState } from "vuex";
import ProfessionalCard from "@/components/professional/ProfessionalCard.vue";

export default {
  mixins: [paramsMixin],
  components: { OrderBy, ProfessionalCard },
  data() {
    const selected_categories = this.$route.query?.category_id__in
      ? this.$route.query.category_id__in.split(",")
      : [];
    const selected_state = this.$route.query.state
      ? JSON.parse(localStorage.getItem("state"))
      : [];
    const selected_cities = this.$route.query.city
      ? JSON.parse(localStorage.getItem("city"))
      : [];
    return {
      selected_categories,
      selected_tags: {},
      showLoading: true,
      order: null,
      paramsFilter: {
        category_id__in: "",
        order: "random",
        q: "",
        page: 1,
        city: "",
        state: "",
        ...this.$route.query
      },
      timeout: 0,
      selected_state,
      selected_cities
    };
  },
  created() {
    this.$store.dispatch("professional/getCategories").then();
    this.$store.dispatch("districts/getDistricts");
  },
  computed: {
    ...mapState("professional", [
      "professionals",
      "categories",
      "tags",
      "pagination"
    ]),
    ...mapState("districts", ["districts", "cities"]),
    haveProfile() {
      return this.$user.profile?.id;
    },
    currentOrder() {
      if (this.order == "rating_count") return "Mais avaliações";

      if (this.order == "rating") return "Mais avaliados";

      if (this.order == "old") return "Mais antigos primeiro";

      return "Mais recentes primeiro";
    },

    normalizedTags() {
      const tagObj = {};
      Object.entries(this.tags).forEach(([k, v]) => {
        tagObj[normalizeText(k)] = v;
        this.selected_tags[k] = true;
      });

      return tagObj;
    },
    citiess() {
      if (this.$route.query.state) {
        return this.cities;
      } else {
        return [];
      }
    }
  },
  watch: {
    paramsFilter: {
      deep: true,
      handler(newVal) {
        if (newVal.q != "") {
          this.timeout = 3000;
        }
        setTimeout(() => {
          this.$router.push({
            ...this.$route,
            query: {
              ...clearObject(this.$route.query),
              ...clearObject(newVal)
            }
          });
        }, this.timeout);
      }
    },
    // selected_categories(newV) {
    //   if (newV) {
    //     this.$router.push({
    //       ...this.$route,
    //       query: {
    //         ...clearObject(this.$route.query),
    //         ...clearObject(newV),
    //         category_id__in: newV.join(","),
    //       },
    //     });
    //   }
    // },
    categories(newV) {
      this.updateNameMap();
    }
    // selected_state(newV) {
    //   this.$router.push({
    //     ...this.$route,
    //     query: {
    //       ...clearObject(this.$route.query),
    //       state: newV,
    //     },
    //   });
    // },
  },
  methods: {
    onFinally() {
      this.showLoading = false;
    },
    tagSize(tag) {
      if (tag?.length < 20) return `col-`;

      if (tag?.length < 50) return `col-5`;

      return `col-6`;
    },
    normalizeCat(v) {
      return normalizeText(v);
    },
    formatHomeTime(timeStr) {
      if (!!timeStr) {
        return timeStr
          .split("-")
          .reverse()
          .join("/");
      }

      return "Não certificado";
    },
    submitCategoriesFilter(choosed_categories) {
      if (choosed_categories) {
        const ids = choosed_categories.map(cat => cat.id);
        this.selected_categories = ids;
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            category_id__in: ids.join(",")
          }
        });
      }
    },
    clearFilters() {
      this.$router.push({
        ...this.$route,
        query: null
      });
      localStorage.removeItem("state");
      localStorage.removeItem("city");
    },

    submitStates(choosed_district) {
      //this.paramsFilter.state = choosed_district[0].sigla;
      if (choosed_district) {
        localStorage.removeItem("state");
        this.selected_state.push(choosed_district[0].id);
        localStorage.setItem("state", JSON.stringify(this.selected_state));
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            state: choosed_district[0].sigla
          }
        });
        this.$store.dispatch("districts/getCities", choosed_district[0].id);
      }
    },
    submitCities(choosed_cities) {
      if (choosed_cities) {
        localStorage.removeItem("city");
        const citiesNames = choosed_cities.map(cit => cit.name).join(",");
        const citiesID = choosed_cities.map(cit => cit.id);
        localStorage.setItem("city", JSON.stringify(citiesID));
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            city: citiesNames
          }
        });
      }
    }
  },
  filters: {
    formatSocialMedia(text) {
      if (!text || !text.length) return "";

      if (text.length > 26) return text.slice(0, 26) + "...";

      return text;
    }
  }
};
</script>

<style lang="scss" scoped>
.views-professionals {
  ::v-deep .occupation-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 90%;
  }

  ::v-deep .custom-checkbox {
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #202124;
    }
  }
  .text-city {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #737373;
  }

  .tag-box {
    background: #f4f7f9;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.015em;
    color: #737373;
    height: fit-content;
    width: fit-content;
    max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 45%;
    padding: 1px 3px;
  }

  .time-house {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #737373;
  }
  .time-house-number {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.015em;
    color: #737373;
  }
  .img-professional {
    display: block;
    border: none;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
  }

  hr {
    margin-top: 0;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
  }
}
</style>
