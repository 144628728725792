<template>
  <div class="professional-card-component d-flex w-100">
    <router-link
      :to="{
        name: 'professional-profile',
        params: { slug: professional.slug }
      }"
      class="text-decoration-none w-100 d-flex"
    >
      <card shape="small" class="w-100">
        <div class="text-center">
          <div class="cover"></div>
          <!-- <div
            class="img-professional"
            :style="`background-image: url(${professional.perfil_thumbnail});`"
          ></div> -->
        </div>

        <div class="card-body d-flex flex-column mt-n2">
          <div class="d-flex  mb-3">
            <div class="position-relative mr-4 mt-n4">
              <img
                height="83"
                width="83"
                class="emblem-img "
                :src="professional.perfil_thumbnail"
                :alt="professional.emblem.name"
              />
              <img
                width="29.74"
                height="29.74"
                :src="professional.emblem.photo"
                alt=""
                class="emblem"
              />
            </div>
            <div class="flex-fill">
              <h6 class="professional-name mb-0 font-weight-bold text-dark">
                {{ professional.name }}
              </h6>
              <div class="d-flex flex-column">
                <small
                  class="professional-category"
                  v-if="professional.category.name"
                >
                  {{ professional.category.name }}
                </small>
                <small
                  v-if="professional.city && professional.state"
                  class="text-city text-black"
                  >{{ professional.city }}/{{ professional.state }}</small
                >
              </div>
            </div>
          </div>
          <p class="bio mb-2 pb-1">
            <span v-if="professional.bio">{{ professional.bio }}</span>
          </p>
          <hr />
          <div
            v-if="professional.tags && professional.tags.length"
            class="d-flex flex-wrap mx-n1 mt-n1"
          >
            <div
              v-for="(t, i) in professional.tags.slice(0, 6)"
              :key="`${professional.name}${i}`"
              class="tag-box m-1"
              :title="t.name"
            >
              {{ t.name }}
            </div>
          </div>
          <div v-else class="d-flex flex-wrap mx-n1">
            <div class="tag-box m-1">
              {{ professional.category.name }}
            </div>
          </div>
          <div class="flex-grow-1 d-flex align-items-end mt-3">
            <div
              v-if="professional.get_humanize_emblem_time"
              class="d-flex align-items-center"
            >
              <i class="icon-history mr-2 text-yellow font-weight-bold"></i>
              <span class="time-house mr-1">Tempo de casa:</span>
              <span class="time-house-number">
                {{ timeOfHouse }}
              </span>
            </div>
          </div>
        </div>
      </card>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    professional: {
      type: Object
    }
  },
  computed: {
    timeOfHouse() {
      let time = this.professional.get_humanize_emblem_time.split(" ");
      return time[1];
    }
  }
};
</script>
<style lang="scss" scoped>
.professional-card-component {
  ::v-deep .occupation-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 90%;
  }

  .professional-name {
    font-size: 16px !important;
    line-height: 110% !important;
    letter-spacing: 0.015em;
    color: #0a0a0a;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 100%;
  }

  i {
    font-size: 16px;
  }

  .cover {
    width: 100%;
    height: 164px;
    background-color: #737373;
  }

  ::v-deep .custom-checkbox {
    label {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #202124;
    }
  }
  .text-city {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #737373;
  }

  .tag-box {
    background: #f4f7f9;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.015em;
    color: #737373;
    height: fit-content;
    width: fit-content;
    max-width: fit-content;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 45%;
    padding: 1px 3px;
  }

  .time-house {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #737373;
  }
  .time-house-number {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.015em;
    color: #737373;
  }
  /* .img-professional {
    display: block;
    border: none;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
  } */

  hr {
    margin-top: 0;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    height: 1px;
    width: 100%;
  }

  .emblem {
    position: absolute;
    border-radius: 50%;
    left: 55px;
    top: 60px;
  }

  .professional-category {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #595959;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 90%;
  }

  .bio {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #0a0a0a;
  }
}
</style>
