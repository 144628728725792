<template>
  <div
    class="
      wrapper-dropdown
      d-flex
      align-items-center
      justify-content-center justify-content-lg-end
    "
  >
    <b-dropdown class="mt-n2" no-caret variant="link">
      <template #button-content>
        <button class="btn">
          <i class="icon-order-by" /> {{ currentOrder.title }}
        </button>
      </template>
      <b-dropdown-item
        v-for="(orderItem, indexOrder) in orders"
        :key="`order-${orderItem.id}`"
        @click="setCurrentOrder(orderItem, indexOrder)"
        class="order-option"
      >
        {{ orderItem.title }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      orders: [
        {
          id: 1,
          title: "Mais recentes primeiro",
          filterValue: "desc"
        },
        {
          id: 2,
          title: "Mais antigos primeiro",
          filterValue: "asc"
        }
      ],
      currentOrderIndex: this.value == "asc" ? 1 : 0
    };
  },

  computed: {
    currentOrder() {
      return this.orders[this.currentOrderIndex];
    }
  },

  methods: {
    setCurrentOrder(orderItem, indexOrder) {
      this.currentOrderIndex = indexOrder;
      this.$emit("input", orderItem.filterValue);
    },
    currentOrderprops() {
      if (this.value == "asc") {
        return 0;
      } else {
        return 1;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper-dropdown ::v-deep {
  ul {
    width: 100% !important;
  }
  i {
    color: #ffcc00;
  }
}
</style>
